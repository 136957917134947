import Image from "next/image";
import Link from "next/link";
import React from "react";
import { raleway } from "@/app/fonts";
const Logo = () => {
  return <Link href="/" className={`${raleway.variable} flex items-center gap-[5px] sm:gap-2`} data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <Image src="/icons/shared/logo-icon.svg" width={32} height={32} alt="kaguya logo" className="h-5 w-5 sm:h-7 sm:w-7 rounded-[50%]" data-sentry-element="Image" data-sentry-source-file="Logo.tsx" />
      <span className={`font-raleway font-semibold text-white text-[28px] leading-[35px]`}>
        Kaguya
      </span>
    </Link>;
};
export default Logo;