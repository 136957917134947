import React from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { signOut } from "@/app/(auth)/login/actions";
const LogoutButton = (props: React.ComponentProps<typeof Button>) => {
  const {
    className,
    ...rest
  } = props;
  return <Button onClick={() => signOut()} type="button" variant="link" className={cn("flex items-center justify-start h-fit w-full text-left gap-2 p-0 text-sm font-medium text-white", className)} {...rest} data-sentry-element="Button" data-sentry-component="LogoutButton" data-sentry-source-file="LogoutButton.tsx">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="LogoutButton.tsx">
        <path d="M7.62134 5.15016C6.17153 6.11229 5.07006 7.51569 4.48 9.15259C3.88993 10.7895 3.84263 12.5729 4.3451 14.2388C4.84757 15.9047 5.87311 17.3645 7.26988 18.4021C8.66665 19.4397 10.3604 20 12.1004 20C13.8404 20 15.5342 19.4397 16.931 18.4021C18.3277 17.3645 19.3533 15.9047 19.8557 14.2388C20.3582 12.5729 20.3109 10.7895 19.7208 9.15259C19.1308 7.51569 18.0293 6.11229 16.5795 5.15016M12.1009 2V9.20037" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="LogoutButton.tsx" />
      </svg>
      Log out
    </Button>;
};
export default LogoutButton;